/**
 * @generated SignedSource<<b6457c64d3562f69358664a0c9f54e06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type scmpWidgetConfigAppConfigQuery$variables = Record<PropertyKey, never>;
export type scmpWidgetConfigAppConfigQuery$data = {
  readonly appConfig: {
    readonly json: unknown | null | undefined;
  } | null | undefined;
};
export type scmpWidgetConfigAppConfigQuery = {
  response: scmpWidgetConfigAppConfigQuery$data;
  variables: scmpWidgetConfigAppConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "entityId": "scmp_pwa_widget"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "json",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "scmpWidgetConfigAppConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "AppConfig",
        "kind": "LinkedField",
        "name": "appConfig",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": "appConfig(filter:{\"entityId\":\"scmp_pwa_widget\"})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "scmpWidgetConfigAppConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "AppConfig",
        "kind": "LinkedField",
        "name": "appConfig",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": "appConfig(filter:{\"entityId\":\"scmp_pwa_widget\"})"
      }
    ]
  },
  "params": {
    "cacheID": "a66ee15689f1f912c9069f14592d15b9",
    "id": null,
    "metadata": {},
    "name": "scmpWidgetConfigAppConfigQuery",
    "operationKind": "query",
    "text": "query scmpWidgetConfigAppConfigQuery {\n  appConfig(filter: {entityId: \"scmp_pwa_widget\"}) {\n    json\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f68e6b6205726de1ee10ea18f058dafd";

export default node;
