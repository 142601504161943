import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { userFollowAtom } from "scmp-app/components/follow-button/atoms";

import { useSnapshotState } from ".";

const followedThreshold = 3;

export const useMyNews = () => {
  const { data } = useAtomValue(userFollowAtom);

  const { value: isVisitedMyNewsPage } = useFallbackStorageValue("isVisitedMyNewsPage", {
    initializeWithValue: true,
    parse: raw => raw,
  });

  const computedFollowedList = useMemo(() => Object.values(data ?? {}).flat(), [data]);

  const [dataReference] = useSnapshotState(data);
  // Check the followed value by snapshot
  const initialHasEnoughFollowedItems = useMemo(
    () => Object.values(dataReference ?? {}).flat().length >= followedThreshold,
    [dataReference],
  );

  // Check the followed value by variable list
  const hasEnoughFollowedItems = useMemo(
    () => computedFollowedList.length >= followedThreshold,
    [computedFollowedList],
  );

  return {
    hasEnoughFollowedItems,
    initialHasEnoughFollowedItems,
    isVisitedMyNewsPage,
  };
};
