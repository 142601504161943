import { theme, useResponsive } from "@product/scmp-sdk";
import { useSyncedRef } from "@react-hookz/web";
import { useCallback } from "react";
import type { YouTubePlayer } from "youtube-player/dist/types";

import { useIntersection } from "scmp-app/lib/hooks";
type UseIntersectionHandlerParameters = {
  adsManager: OrUndefined<google.ima.AdsManager>;
  isAdPlaying: boolean;
  isAdStarted: boolean;
  player: OrUndefined<YouTubePlayer>;
};

export const useIntersectionHandler = ({
  adsManager,
  isAdPlaying,
  isAdStarted,
  player,
}: UseIntersectionHandlerParameters) => {
  const latestIsAdStartedReference = useSyncedRef(isAdStarted);
  const latestIsAdPlayingReference = useSyncedRef(isAdPlaying);

  const { captureIntersectionElement, intersection } = useIntersection({
    threshold: 0.5,
  });
  const isDesktopUp = useResponsive(theme.breakpoints.up("desktop"));

  const handleIsIntersecting = useCallback(() => {
    if (latestIsAdStartedReference.current) {
      adsManager?.resume();
    }

    // Desktop up will play video
    if (!latestIsAdStartedReference.current && isDesktopUp) {
      try {
        void player?.playVideo();
      } catch (error) {
        console.error("handleIsIntersecting playVideo", error);
      }
    }
  }, [adsManager, isDesktopUp, latestIsAdStartedReference, player]);

  const handleIsNotIntersecting = useCallback(() => {
    if (latestIsAdStartedReference.current && latestIsAdPlayingReference.current) {
      adsManager?.pause();
    }
    try {
      void player?.pauseVideo();
    } catch (error) {
      console.error("handleIsNotIntersecting pauseVideo", error);
    }
  }, [adsManager, latestIsAdPlayingReference, latestIsAdStartedReference, player]);

  return {
    captureIntersectionElement,
    handleIsIntersecting,
    handleIsNotIntersecting,
    intersection,
  };
};
