import type { EmbeddedSpeechifyExperience } from "https://storage.googleapis.com/speechify-api-cdn/speechifyapi.min.mjs";
import { atom } from "jotai";
import type { YouTubePlayer } from "youtube-player/dist/types";

type OnlyOnePlayingPlayer = {
  adsManager?: google.ima.AdsManager;
  articleSpeechifyPlayer?: EmbeddedSpeechifyExperience;
  currentYoutubeVideoId?: string;
  stopYoutubeAutoplay: boolean;
  youtubePlayer?: YouTubePlayer;
};

export const onlyOnePlayingPlayerAtom = atom<OnlyOnePlayingPlayer>({
  adsManager: undefined,
  articleSpeechifyPlayer: undefined,
  currentYoutubeVideoId: undefined,
  stopYoutubeAutoplay: false,
  youtubePlayer: undefined,
});
