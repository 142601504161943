import { useSyncedRef } from "@react-hookz/web";
// TODO: react-hookz will drop the support on cjs (refs: https://github.com/react-hookz/web/pull/963)
// need to fix this after 963 is approved
import { useCookieValue } from "@react-hookz/web/cjs/useCookieValue";
import { useMemo } from "react";

// Reactive by the cookieName, will not be reactive if cookie is modified externally and parse is new
export function useParsedCookie<T>(
  cookieName: string,
  parser: (raw: string) => T,
  options?: UseParsedCookieOption,
) {
  const [raw, update, remove] = useCookieValue(cookieName, options);

  // Use latest parser ref to avoid re-run different re-run of the memorized
  const latestParser = useSyncedRef(parser);
  const memorized = useMemo(() => raw && latestParser.current(raw), [latestParser, raw]);

  return { remove, update, value: memorized };
}

export type UseParsedCookieOption = {
  initializeWithValue?: boolean;
} & Cookies.CookieAttributes;
