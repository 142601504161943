import { removeAtMedia, theme } from "@product/scmp-sdk";
import React, { forwardRef, useCallback, useState } from "react";
import { JsonLd } from "react-schemaorg";
import type { ImageObject } from "schema-dts";

import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";

import type { ImageProps } from "./styles";
import { StyledImage } from "./styles";

export type Props = {
  className?: string;
  disableLinkingData?: boolean;
  lazyload?: boolean;
  responsiveVariant?: ResponsiveVariants<{
    aspectRatio: string;
    url: string;
    width: number;
  }>;
} & Omit<ImageProps, "$hasError"> &
  Omit<React.ImgHTMLAttributes<HTMLImageElement>, "height" | "loading" | "width">;

export const BaseImage = forwardRef<HTMLImageElement, Props>(
  (
    { disableLinkingData = false, lazyload = true, responsiveVariant, src, title, ...attribs },
    forwardReference,
  ) => {
    const [hasError, setHasError] = useState<boolean | undefined>(undefined);

    const viewportSizeKeyMap = [
      { key: "desktopUp", value: theme.breakpoints.up("desktop") },
      { key: "tabletUp", value: theme.breakpoints.up("tablet") },
      { key: "mobileUp", value: theme.breakpoints.up("mobile") },
    ] as const;

    // Workaround event before hydration can't be listen. Reference https://github.com/facebook/react/issues/15446
    const captureElement = useCallback(
      (element: HTMLImageElement | null) => {
        if (element?.complete) {
          setHasError(element.naturalWidth === 0);
        }

        if (forwardReference) {
          if (typeof forwardReference === "function") {
            forwardReference(element);
          } else {
            forwardReference.current = element;
          }
        }
      },
      [forwardReference],
    );

    const handleRenderImage = () => (
      <>
        <StyledImage
          {...attribs}
          $hasError={hasError}
          loading={lazyload ? "lazy" : "eager"}
          onError={() => {
            setHasError(true);
          }}
          onLoad={() => {
            setHasError(false);
          }}
          ref={captureElement}
          src={src}
          title={title}
        />
        {src && !disableLinkingData && (
          <JsonLd<ImageObject>
            item={{
              "@context": "https://schema.org",
              "@type": "ImageObject",
              caption: title,
              url: src,
            }}
          />
        )}
      </>
    );

    if (!responsiveVariant) return handleRenderImage();

    return (
      <picture>
        {viewportSizeKeyMap.map(({ key, value }, index) => (
          <source
            data-aspect-ratio={responsiveVariant?.[key].aspectRatio}
            data-width={responsiveVariant?.[key].width}
            key={index}
            media={removeAtMedia(value)}
            srcSet={responsiveVariant?.[key].url ?? undefined}
          />
        ))}
        {handleRenderImage()}
      </picture>
    );
  },
);

BaseImage.displayName = "BaseImage";
