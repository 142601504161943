import styled from "@emotion/styled";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import { googleImaAtom } from "scmp-app/components/advertisement/google-ima/atoms";
import { useIntersection } from "scmp-app/lib/hooks";

import { VideoPlayMode } from "./enums";
import type { VideoTitleContainerProps } from "./scmp-youtube-video-footer/styles";
import type { Props as SCMPYouTubeVideoPlayerProps } from "./scmp-youtube-video-player";
import { SCMPYouTubeVideoPlayer } from "./scmp-youtube-video-player";
import { SCMPYoutubeVideoPreview } from "./scmp-youtube-video-preview";
import { SCMPYoutubeVideoContainer, StyledSCMPYouTubeVideoFooter } from "./styles";

export type Props = {
  className?: string;
  duration?: string;
  enableAutoPlay: boolean;
  imageSource?: string;
  index: number;
  initialVideoPlayMode?: VideoPlayMode;
  isEnabledImaManager: boolean;
  isHeroVideo: boolean;
  isMuteVideo?: boolean;
  mobileImageSource?: string;
  withFooter?: { variant: VideoTitleContainerProps["$variant"]; withIcon?: boolean };
  youtubeVideoId?: string;
} & Omit<SCMPYouTubeVideoPlayerProps, "queryReference" | "videoId" | "videoPlayMode">;

const Component: FunctionComponent<Props> = ({
  className,
  duration,
  enableAutoPlay,
  imageSource,
  index = 0,
  initialVideoPlayMode = VideoPlayMode.Unset,
  isEnabledImaManager,
  isHeroVideo,
  isMuteVideo = true,
  title,
  withFooter,
  youtubeVideoId,
  ...attribs
}: Props) => {
  const [videoPlayMode, setVideoPlayMode] = useState(initialVideoPlayMode);

  const { isImaReady } = useAtomValue(googleImaAtom);
  const renderPreviewOrVideo = () =>
    youtubeVideoId && videoPlayMode !== VideoPlayMode.Unset && isImaReady ? (
      <SCMPYouTubeVideoPlayer
        enableAutoPlay={enableAutoPlay}
        index={index}
        isEnabledImaManager={isEnabledImaManager}
        isMuteVideo={isMuteVideo}
        videoId={youtubeVideoId}
        videoPlayMode={videoPlayMode}
        {...{ ...attribs, title }}
      />
    ) : (
      <SCMPYoutubeVideoPreview
        duration={duration}
        imageSource={imageSource}
        isHeroVideo={isHeroVideo}
        onClick={() => {
          enableAutoPlay
            ? setVideoPlayMode(VideoPlayMode.Autoplay)
            : setVideoPlayMode(VideoPlayMode.Manual);
        }}
        title={title}
      />
    );

  const { captureIntersectionElement, intersection } = useIntersection({
    threshold: 0.45,
  });
  const isDesktopUp = useResponsive(theme.breakpoints.up("desktop"));

  useEffect(() => {
    if (
      initialVideoPlayMode === VideoPlayMode.Unset &&
      intersection?.isIntersecting &&
      isDesktopUp
    ) {
      setVideoPlayMode(VideoPlayMode.Autoplay);
    }
  }, [intersection?.isIntersecting, isDesktopUp, initialVideoPlayMode]);

  return (
    <SCMPYoutubeVideoContainer className={className} ref={captureIntersectionElement}>
      {renderPreviewOrVideo()}
      {withFooter && (
        <StyledSCMPYouTubeVideoFooter
          title={title}
          variant={withFooter.variant}
          withIcon={withFooter.withIcon}
        />
      )}
    </SCMPYoutubeVideoContainer>
  );
};

Component.displayName = "SCMPYoutubeVideo";
export const SCMPYoutubeVideo = styled(Component)``;
