import { css } from "@emotion/react";
import styled from "@emotion/styled";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import YouTube from "@vip30/react-youtube";

const VolumeControlIconStyle = css`
  inline-size: 36px;
  block-size: 36px;

  color: #ffffff;

  cursor: pointer;
`;

export const StyledMuteIcon = styled(VolumeUpIcon)`
  ${VolumeControlIconStyle}
`;
export const StyledUnmuteIcon = styled(VolumeOffIcon)`
  ${VolumeControlIconStyle}
`;

export const Container = styled.div``;

export const VideoContainer = styled.div`
  position: relative;

  inline-size: 100%;
  block-size: 0;
  max-inline-size: 100%;
  padding-block-end: 56.25%;
`;

export const StyledYouTube = styled(YouTube)`
  position: absolute;

  inline-size: 100%;
  block-size: auto;

  iframe {
    aspect-ratio: 16 / 9;

    inline-size: 100%;
    block-size: 100%;
  }
`;

export const AdControlButtonContainer = styled.div`
  position: absolute;
  inset-block: 0 0;
  inset-inline-start: 0;
  z-index: 1;
`;

export const AdContainer = styled.div`
  inline-size: 100% !important;
  block-size: 0 !important;
  padding-block-end: 56.25%;

  /* Override the inline style generated by IMA SDK */
  .ima-control-button {
    padding-block: 0;
    padding-inline: 6px;
  }
  .ima-controls,
  div > div,
  iframe {
    z-index: 1;
  }

  div > div {
    inline-size: 100% !important;
    block-size: 100% !important;
  }
  iframe {
    position: absolute !important;
    inset-block-start: 0;
    inset-inline-start: 0;

    inline-size: 100%;
    block-size: calc(100% - 40px);
  }
`;
