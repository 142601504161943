import { useCallback, useEffect, useState } from "react";
import type { YouTubePlayer } from "youtube-player/dist/types";

export const useAdVolumeControl = ({
  adsManager,
  isAdStarted,
  youtubePlayer,
}: {
  adsManager?: google.ima.AdsManager;
  isAdStarted: boolean;
  youtubePlayer?: YouTubePlayer;
}) => {
  const [isMute, setIsMute] = useState(true);

  const handleOnVolumeChange = useCallback(() => {
    setIsMute(false);
    void youtubePlayer?.unMute();
  }, [youtubePlayer]);

  const handleOnMute = useCallback(() => {
    setIsMute(true);
    void youtubePlayer?.mute();
  }, [youtubePlayer]);

  const handleChangeVolume = useCallback(
    (mute: boolean) => {
      adsManager?.setVolume(mute ? 0 : 1);
      setIsMute(mute);
    },
    [adsManager],
  );

  const handleOnAdStarted = useCallback(async () => {
    const isMuted = (await youtubePlayer?.isMuted()) ?? false;
    handleChangeVolume(isMuted);
  }, [handleChangeVolume, youtubePlayer]);

  useEffect(() => {
    if (!adsManager) return;
    adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, handleOnAdStarted);
    return () => {
      adsManager.removeEventListener(google.ima.AdEvent.Type.STARTED, handleOnAdStarted);
    };
  }, [adsManager, handleOnAdStarted]);

  useEffect(() => {
    if (!adsManager || !isAdStarted) return;
    adsManager.addEventListener(google.ima.AdEvent.Type.VOLUME_CHANGED, handleOnVolumeChange);
    adsManager.addEventListener(google.ima.AdEvent.Type.VOLUME_MUTED, handleOnMute);

    return () => {
      adsManager.removeEventListener(google.ima.AdEvent.Type.VOLUME_CHANGED, handleOnVolumeChange);
      adsManager.removeEventListener(google.ima.AdEvent.Type.VOLUME_MUTED, handleOnMute);
    };
  }, [adsManager, handleOnMute, handleOnVolumeChange, isAdStarted]);

  return {
    handleChangeVolume,
    isMute,
  };
};
