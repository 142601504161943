import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

import { getGoogleAnalyticsClientId } from "scmp-app/components/tracking/google-analytics/apis";
import { accountAtom } from "scmp-app/lib/account";

import { syncMirrorGAUserIds } from ".";

export function useMirrorTrackPageLoad() {
  const { user } = useAtomValue(accountAtom);

  useEffect(() => {
    const run = async () => {
      const gaClientId = await getGoogleAnalyticsClientId();

      if (!gaClientId) return;

      syncMirrorGAUserIds(gaClientId);
    };
    void run();
  }, [user]);
}

export function useLighthouseMapValue() {
  return useFallbackStorageValue<Record<string, unknown>>("lh2_map", {
    parse(data) {
      if (!data) return {};
      try {
        return JSON.parse(data) as Record<string, unknown>;
      } catch {
        return {};
      }
    },
    stringify(data) {
      return JSON.stringify(data);
    },
  });
}
