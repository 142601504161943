import type { YouTubePlayer } from "@vip30/react-youtube";

import type { Event, UntypedEvent } from "./types";

declare global {
  type CustomEventPayload = Record<string, string | string[]>;
  interface Window {
    MIRROR?: {
      video?: {
        add(player: YouTubePlayer, name: string): void;
        load(adId: string, name: string): void;
      };
    };
    MIRROR_EVENT?: {
      push: (parameters: {
        event: string;
        name?: string;
        payload: CustomEventPayload;
        source?: string;
      }) => void;
    };
  }
}

export const syncMirrorGAUserIds = (gaUserId: string) => {
  window.MIRROR_EVENT &&
    window.MIRROR_EVENT.push({
      event: "si",
      payload: {
        ga: [gaUserId],
      },
    });
};

export const setContentInterest = (payload: CustomEventPayload) => {
  if (!window.MIRROR_EVENT) return;
  window.MIRROR_EVENT?.push({
    event: "ce",
    name: "content_interest",
    payload,
  });
  window.MIRROR_EVENT?.push({
    event: "ta",
    payload,
    source: "content_interest",
  });
};

export function sendMirrorCustomEvent<Untyped extends false | true = false>(
  { event_name, ...eventParameters }: Untyped extends true ? UntypedEvent : Event,
  _options?: { untyped: Untyped },
) {
  if (window.MIRROR_EVENT === undefined) return;
  window.MIRROR_EVENT.push({
    event: "ce",
    name: event_name,
    payload: {
      ...(eventParameters as CustomEventPayload),
    },
  });
}

export const mirrorVideoAdd = (player: YouTubePlayer, name: string) => {
  if (!window.MIRROR?.video) return;
  window.MIRROR?.video?.add(player, name);
};

export const mirrorVideoLoad = (adId: string, name: string) => {
  if (!window.MIRROR?.video) return;
  window.MIRROR?.video?.load(adId, name);
};
