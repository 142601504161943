import { useDeepCompareEffect } from "@react-hookz/web";
import first from "lodash/first";
import { useCallback, useMemo, useState } from "react";

export const useIntersection = ({ root, rootMargin, threshold }: IntersectionObserverInit) => {
  const [elements, setElements] = useState<HTMLElement[]>([]);
  const [intersections, setIntersections] = useState<IntersectionObserverEntry[]>([]);
  const [shouldDisable, setShouldDisable] = useState<boolean>(false);

  const element = useMemo(() => first(elements) ?? null, [elements]);
  const intersection = useMemo(() => first(intersections) ?? null, [intersections]);

  const captureIntersectionElement = useCallback(
    (dom: HTMLElement | null) => {
      if (!dom) return;
      if (elements.some(element => element.isSameNode(dom))) return;
      setElements(current => [...current, dom]);
    },
    [elements],
  );

  useDeepCompareEffect(() => {
    if (shouldDisable || elements.length === 0 || typeof IntersectionObserver !== "function") {
      return () => {
        setIntersections([]);
      };
    }

    const handler = (entries: IntersectionObserverEntry[]) => {
      setIntersections(entries);
    };
    const observer = new IntersectionObserver(handler, {
      root,
      rootMargin,
      threshold,
    });
    elements.forEach(element => {
      observer.observe(element);
    });

    return () => {
      setIntersections([]);
      observer.disconnect();
    };
  }, [elements, root, rootMargin, threshold, shouldDisable]);

  return {
    captureIntersectionElement,
    element,
    elements,
    intersection,
    intersections,
    setShouldDisable,
  };
};
