import type { FunctionComponent } from "react";

import { StyledVideoRecorder, VideoTitle, VideoTitleContainer } from "./styles";
import type { VideoTitleContainerProps } from "./styles";

export type Props = {
  className?: string;
  isHeroVideo?: boolean;
  title?: string;
  variant?: VideoTitleContainerProps["$variant"];
  withIcon?: boolean;
};

export const SCMPYouTubeVideoFooter: FunctionComponent<Props> = ({
  className,
  isHeroVideo,
  title,
  variant,
  withIcon = true,
}) => {
  if (!title) return null;
  return (
    <VideoTitleContainer $isHeroVideo={isHeroVideo} $variant={variant} className={className}>
      {withIcon && <StyledVideoRecorder />}
      <VideoTitle>{title}</VideoTitle>
    </VideoTitleContainer>
  );
};

SCMPYouTubeVideoFooter.displayName = "SCMPYouTubeVideoFooter";
