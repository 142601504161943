/**
 * @generated SignedSource<<48d14898e3af32c8e20cf40c08bb6276>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type scmpYoutubeVideoPlayerQuery$variables = {
  applicationId: string;
  entityId: string;
};
export type scmpYoutubeVideoPlayerQuery$data = {
  readonly content: {
    readonly headline: string | null | undefined;
    readonly sections: ReadonlyArray<{
      readonly value: ReadonlyArray<{
        readonly name: string;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type scmpYoutubeVideoPlayerQuery = {
  response: scmpYoutubeVideoPlayerQuery$data;
  variables: scmpYoutubeVideoPlayerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "applicationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "contentType",
    "value": "VIDEO"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "applicationId",
        "variableName": "applicationId"
      },
      {
        "kind": "Variable",
        "name": "entityId",
        "variableName": "entityId"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headline",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "scmpYoutubeVideoPlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SectionSegment",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Section",
                "kind": "LinkedField",
                "name": "value",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "scmpYoutubeVideoPlayerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SectionSegment",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Section",
                "kind": "LinkedField",
                "name": "value",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dcccead8ce8ed59667b83314f8eb6b9f",
    "id": null,
    "metadata": {},
    "name": "scmpYoutubeVideoPlayerQuery",
    "operationKind": "query",
    "text": "query scmpYoutubeVideoPlayerQuery(\n  $applicationId: String!\n  $entityId: String!\n) {\n  content(contentType: VIDEO, filter: {entityId: $entityId, applicationId: $applicationId}) {\n    __typename\n    headline\n    sections {\n      value {\n        name\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9149f7697182c5bac972fcdaaaed91b";

export default node;
