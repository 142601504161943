import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontPlayfairDisplay, fontRoboto } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

import VideoRecorder from "./video-recorder.svg";

type Variant = "default" | "magazines-style";

export type VideoTitleContainerProps = {
  $isHeroVideo?: boolean;
  $variant?: Variant;
};

export const VideoTitle = styled.div``;

export const StyledVideoRecorder = styled(VideoRecorder)`
  flex-basis: 24px;
  flex-shrink: 0;
  align-self: flex-start;

  margin-inline-end: 8px;
`;

export const VideoTitleContainer = styled.div<VideoTitleContainerProps>`
  ${props => {
    switch (props.$variant) {
      case "default":
        return css`
          ${VideoTitle} {
            color: #110000;
            font-size: 14px;
            font-family: ${fontRoboto};
            line-height: 19px;
          }

          ${props.$isHeroVideo
            ? css`
                display: none;

                ${props.theme.breakpoints.up("tablet")} {
                  display: flex;
                  align-items: center;

                  margin-block-start: 12px;
                  margin-inline: 14px;
                }
              `
            : css`
                display: flex;

                margin-block-start: 8px;
                margin-inline: 20px;
                ${props.theme.breakpoints.up("tablet")} {
                  margin-inline: 14px;
                }
              `}
        `;
      case "magazines-style":
        return css`
          ${VideoTitle} {
            margin-inline: auto;
            padding-block: 32px 48px;
            padding-inline: 20px;

            font-weight: 400;
            font-family: ${fontPlayfairDisplay};
            font-style: normal;
            text-align: center;

            ${props.theme.breakpoints.only("mobile")} {
              color: #ffffff;
              font-size: 24px;
              line-height: 30px;

              background-color: #222222;
              ${setFullViewportWidthStyles()}
            }

            ${props.theme.breakpoints.up("tablet")} {
              padding-block: 32px 0;

              color: #222222;
              font-size: 32px;
              line-height: 40px;
            }

            ${props.theme.breakpoints.up("desktop")} {
              max-inline-size: 800px;
              padding-block: 32px 0;

              font-size: 40px;
              line-height: 48px;
            }
          }
        `;
    }
  }}
`;
