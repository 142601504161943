import { useAsync, useMountEffect } from "@react-hookz/web";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import { config } from "scmp-app/data";
import type { scmpWidgetConfigAppConfigQuery } from "scmp-app/queries/__generated__/scmpWidgetConfigAppConfigQuery.graphql";

export const useScmpWidgetConfig = () => {
  const environment = useRelayEnvironment();
  const [state, actions] = useAsync(async () => {
    const data = await fetchQuery<scmpWidgetConfigAppConfigQuery>(
      environment,
      graphql`
        query scmpWidgetConfigAppConfigQuery {
          appConfig(filter: { entityId: "scmp_pwa_widget" }) {
            json
          }
        }
      `,
      {},
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    const raw = data.appConfig.json as ScmpPwaWidgetAppConfig;
    return config.general.env === "production" ? raw.production : raw.dev;
  });

  useMountEffect(() => {
    void actions.execute();
  });

  return { scmpWidgetConfigState: state };
};

type ScmpPwaWidgetAppConfig = AppConfig<{
  discovery_module_sample_content: Record<
    string,
    Array<{ downloadLink: string; headline: string; image: string; type: string }>
  >;
  homepage_survey: {
    controlEnabled: boolean;
    impressionCount: number;
    variantEnabled: boolean;
  };
  my_news: {
    is_enabled_in_ad_profile: boolean;
  };
  notification_widget_download_cta: string;
  onboarding_newsletter_default_check?: boolean;
  share_article_expires_day: string;
}>;
