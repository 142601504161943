import { useSyncedRef } from "@react-hookz/web";

import { useAdVolumeControl, useImaIntegration, type UseImaIntegrationParameters } from "./hooks";

export const useAdIntegration = (parameters: UseImaIntegrationParameters) => {
  const latestParameters = useSyncedRef(parameters);

  const { adsManager, initImaManager, isAdPlaying, isAdStarted, isImaInitialized } =
    useImaIntegration(latestParameters.current);

  const { handleChangeVolume, isMute } = useAdVolumeControl({
    adsManager,
    isAdStarted,
    youtubePlayer: latestParameters.current?.player,
  });

  return {
    adsManager,
    handleChangeVolume,
    initImaManager,
    isAdPlaying,
    isAdStarted,
    isImaInitialized,
    isMute,
  };
};
